import Vue from 'vue'
import VueRouter from 'vue-router'
import WorkbenchLayout1200 from "@/components/layout/Workbench/WorkbenchLayout1200";
import {GlobalRouter} from "@/router";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: WorkbenchLayout1200,
    redirect:'/login',
    children: [
    ]
  }
]

const router = new VueRouter({
  routes,
  base:'/index/'
})


GlobalRouter.onInitAfter(router)

export default router
