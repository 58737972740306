import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/index.scss'
import '@/plugins/global'
import '@/ag/index'

Vue.config.productionTip = false

window.$store = store

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

